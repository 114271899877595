@import "./layout/layout";
@import "./base/animations";
@import "./base/base";
@import "./base/typography";
@import "./base/utilities";
@import "./abstract/functions";
@import "./abstract/mixins";
@import "./abstract/variables";

.custom-table {
  position: relative;

  .MuiIconButton-colorSecondary:hover,
  .MuiCheckbox-colorSecondary.Mui-checked:hover,
  .MuiTableCell-head {
    background-color: transparent !important;
  }
  .MuiCheckbox-root {
    width: 100%;
  }
  tr:nth-child(even) {
    background: #f4f6ff;
  }
  tr:nth-child(odd) {
    background: #fff;
  }

  .MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-head {
        color: white;
        :hover {
          color: white !important;
        }
      }
      background-color: #293461;
      border-radius: 25px;
    }
  }
  .MuiTableCell-root {
    border: none !important;
  }
  .MuiTable-root {
    overflow: hidden;
    border-radius: 14px;
    background-color: #fcfcff;
  }
  .MuiTableFooter-root {
    display: none !important;
  }

  .MuiTablePagination-root:last-child {
    border: none;
  }
  .MuiTablePagination-toolbar {
    background-color: transparent;
  }
}

.MuiPickersModal-dialogRoot {
  .MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar,
    .MuiPickersDay-daySelected {
      background-color: #00838c;
    }
    .MuiTypography-colorPrimary {
      color: #00838c;
      :hover {
        color: #00838c;
      }
    }
  }
  .MuiButton-textPrimary {
    color: #00838c;
  }
}

.input-icons {
  .search-icons {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39px;
    width: 35px;
  }
}

.dashboard {
  .PrivateTabIndicator-colorPrimary-108 {
    background-color: #00838c !important;
    width: 131px !important;
    height: 4px !important;
    border-radius: 2px;
    margin: 0 24px;
  }
  .MuiTab-wrapper {
    text-transform: capitalize !important;
    font-family: openSans-bold, sans-serif !important;
    font-size: 16px;
    padding: 5px 10px;
  }
}

.MuiInputBase-input {
  height: 50px;
}

.CreateCase {
  .MuiOutlinedInput-inputMultiline {
    padding-left: 0 !important;
    padding-bottom: 24px !important;
    padding-top: 0 !important;
  }
  .makeStyles-formControl-85 {
    width: 100%;
  }
  .MuiOutlinedInput-multiline {
    padding: 23px 14px;
    color: #293461;
    font-family: openSans-bold, sans-serif !important;
    font-size: 16px;
  }
  .MuiInput-root {
    width: 100%;
    position: relative;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
  }
  .PrivateSwitchBase-root-101 {
    padding: 9px;
    margin-left: -9px;
  }
}

.form-fields {
  .MuiTextField-root {
    width: 100%;
    .MuiInputBase-input {
      font-family: openSans-SemiBold, sans-serif !important;
      color: #293461;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e1e3ee;
    }
  }
}

.required-star {
  font-family: openSans-SemiBold;
  font-size: 12px;
  color: #ff3c3c;
}

.claim-table {
  .MuiOutlinedInput-root {
    height: 32px !important;
  }
  .MuiOutlinedInput-input {
    font-size: 12px !important;
    font-family: openSans-SemiBold !important;
  }
}

.bundle-doc {
  margin-left: 10px;
  .MuiOutlinedInput-root {
    height: 28px !important;
  }
  .MuiOutlinedInput-input {
    font-size: 12px !important;
    font-family: openSans-SemiBold !important;
  }
  .right {
    text-align: right;
    width: 94px;
    margin-left: auto;
  }
}

.MuiExpansionPanelSummary-root {
  padding: unset !important;
}

.input-white {
  .MuiOutlinedInput-root {
    background-color: white !important;
  }
}

.input-height-auto {
  .MuiOutlinedInput-root {
    height: auto !important;
    font-family: openSans-SemiBold !important;
  }
}

.myCases {
  .MuiTableCell-root {
    font-size: 12px !important;
  }
}
.myProfiles {
  tr:first-child {
    th:first-child {
      padding-left: 26px !important;
    }
    th:last-child {
      padding-left: 29px !important;
    }
  }
  .MuiTableCell-root {
    font-size: 12px !important;
    padding: 16px 0 !important;
  }
}

.caps {
  text-transform: uppercase;
}

.paymentHistory {
  .MuiTabs-indicator {
    background-color: #00838c !important;
    height: 4px !important;
    border-radius: 2px;
  }
  .MuiTab-wrapper {
    text-transform: capitalize !important;
    font-family: openSans-bold, sans-serif !important;
    font-size: 16px;
    padding: 5px 10px;
  }
}

.input-no-padding {
  &.MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-root {
    padding: 0 9px !important;
  }
}

.invoice {
  margin-left: 10px;
  .MuiOutlinedInput-root {
    height: 38px !important;
  }
  .MuiOutlinedInput-input {
    font-size: 12px !important;
    font-family: openSans-SemiBold !important;
  }
  .right {
    text-align: right;
    width: 94px;
    margin-left: auto;
  }
}

.disabledArrows {
  * {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.player-controls {
  margin-left: 5px;
  margin-top: 5px;
  .MuiOutlinedInput-root {
    height: 38px !important;
  }
  .MuiOutlinedInput-input {
    font-size: 12px !important;
    font-family: openSans-SemiBold !important;
  }
  .right {
    text-align: right;
    width: 110px;
    margin-left: auto;
  }
}

.custom-material-table {
  position: relative;

  .MuiIconButton-colorSecondary:hover,
  .MuiCheckbox-colorSecondary.Mui-checked:hover,
  .MuiTableCell-head {
    background-color: transparent !important;
  }
  .MuiCheckbox-root {
    width: 100%;
  }
  tr:nth-child(even) {
    background: #f4f6ff;
  }
  tr:nth-child(odd) {
    background: #fff;
  }

  .MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-head {
        color: white;
        :hover {
          color: white !important;
        }
      }
      background-color: #293461;
      border-radius: 25px;
    }
  }
  .MuiTableCell-root {
    border: none !important;
  }
  .MuiTable-root {
    overflow: hidden;
    border-radius: 5px;
    background-color: #fcfcff;
  }
}

.select-container {
  .MuiOutlinedInput-root {
    height: 32px !important;
    width: 210px;
  }
  .MuiOutlinedInput-input {
    font-size: 14px !important;
    font-family: openSans-SemiBold !important;
    color: #293461 !important;
  }
  .right {
    text-align: right;
    width: 50px;
    margin-left: auto;
  }
}

.rtmp {
  display: flex;
  .MuiOutlinedInput-root {
    height: 38px !important;
  }
  .MuiOutlinedInput-input {
    font-size: 12px !important;
    font-family: openSans-SemiBold !important;
  }
  .right {
    text-align: right;
    width: 94px;
    margin-left: auto;
  }
}